import React, { Fragment, useState } from 'react';
import { getallcampaigns, bulkCreateLinks, createCampaign, exportCampaignLinks} from "../api/auth";
import { customAlphabet } from 'nanoid';
import { isAuthenticated } from "../helpers/auth";
import { showErrorMsg, showSuccessMsg } from '../helpers/message';
import { showLoading } from '../helpers/loading';

const CHUNK_SIZE = 10000; // Her bir chunk'ta işlenecek URL sayısı
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 9)


const BulkOperations = () => {
    const [file, setFile] = useState(null);
    const [campaignName, setCampaignName] = useState('');
    const [progress, setProgress] = useState(0);
    const [totalLinks, setTotalLinks] = useState(0);
    const [processedLinks, setProcessedLinks] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [campaignId, setCampaignId] = useState(null);

    const [currentOperation, setCurrentOperation] = useState('');
    const [remainingTime, setRemainingTime] = useState(null);

    const [status, setStatus] = useState({
        isProcessing: false,
        progress: 0,
        processedLinks: 0,
        totalLinks: 0
    });

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension !== 'csv' && fileExtension !== 'txt') {
                setErrorMsg('Please upload a CSV or TXT file.');
                return;
            }
            setFile(file);
        }
    };

    const isValidUrl = (url) => {
        try {
            // URL'i normalize et
            let normalizedUrl = url;
            if (url.startsWith('www.')) {
                normalizedUrl = 'https://' + url;
            } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
                if (url.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/)) {
                    normalizedUrl = 'https://' + url;
                }
            }
    
            new URL(normalizedUrl);
            return true;
        } catch (e) {
            return false;
        }
    };

    const parseUrls = (text) => {
        return text.split('\n')
            .filter(line => {
                const trimmedLine = line.trim();
                // Boş satırları ve sadece noktalı virgül içerenleri filtrele
                return trimmedLine !== '' && trimmedLine !== ';';
            })
            .map(line => {
                // Hem CSV hem de düz metin formatını destekle
                let url;
                if (line.includes(';')) {
                    // CSV formatı için
                    url = line.split(';')
                        .find(part => {
                            const trimmed = part.trim();
                            return trimmed.startsWith('http') || 
                                   trimmed.startsWith('www.') || 
                                   trimmed.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/) // domain kontrolü
                        }) || '';
                } else {
                    // TXT formatı için
                    url = line.trim();
                }
    
                // URL'i temizle ve format kontrolü yap
                url = url.trim();
                
                // URL formatını düzelt
                if (url.startsWith('www.')) {
                    url = 'https://' + url;
                } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    // Domain kontrolü yap
                    if (url.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/)) {
                        url = 'https://' + url;
                    }
                }
    
                return url;
            })
            .filter(url => {
                // Boş URL'leri filtrele ve geçerlilik kontrolü yap
                try {
                    if (!url) return false;
                    new URL(url);
                    return true;
                } catch (e) {
                    console.warn('Invalid URL:', url);
                    return false;
                }
            });
    };

    const processChunk = async (chunk, campaignId) => {
        try {
        const processedChunk = chunk.map(url => {
            const cleanUrl = url.split(';').find(part => part.trim().startsWith('http')) || '';
            const trimmedUrl = cleanUrl.trim();
            
            // URL geçerli değilse bu öğeyi atla
            if (!isValidUrl(trimmedUrl)) {
                console.warn('Invalid URL found:', trimmedUrl);
                return null;
            }
            
            return {
                longUrl: trimmedUrl,
                shortUrl: nanoid(8),
                title: `Bulk Link - ${nanoid(4)}`,
                userId: isAuthenticated()._id,
                campaignId: campaignId
            };
        }).filter(item => item !== null); // null olan öğeleri filtrele
    
        if (processedChunk.length === 0) return 0;

    
        
        await bulkCreateLinks(processedChunk);
        return processedChunk.length;


        } catch (error) {
            console.error('Error processing chunk:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file || !campaignName) {
            setErrorMsg('Please upload a file and provide a campaign name.');
            return;
        }

        // Başlangıç durumunu ayarla
        setStatus({
            isProcessing: true,
            progress: 0,
            processedLinks: 0,
            totalLinks: 0
        });
        setErrorMsg('');
        setSuccessMsg('');

        try {
            const campaignData = {
                name: campaignName,
                userId: isAuthenticated()._id
            };

            const campaignRes = await createCampaign(campaignData);
            if (!campaignRes.data || !campaignRes.data._id) {
                throw new Error('Invalid campaign response');
            }

            const newCampaignId = campaignRes.data._id;
            setCampaignId(newCampaignId);

            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                const urls = parseUrls(text);
                
                if (urls.length === 0) {
                    setErrorMsg('No valid URLs found in the file.');
                    setStatus(prev => ({ ...prev, isProcessing: false }));
                    return;
                }

                // Toplam link sayısını ayarla
                setStatus(prev => ({ ...prev, totalLinks: urls.length }));
                let processed = 0;

                for (let i = 0; i < urls.length; i += CHUNK_SIZE) {
                    const chunk = urls.slice(i, Math.min(i + CHUNK_SIZE, urls.length));
                    const processedCount = await processChunk(chunk, newCampaignId);
                    
                    processed += processedCount;
                    const currentProgress = Math.floor((processed / urls.length) * 100);

                    // Status'u güncelle
                    setStatus(prev => ({
                        ...prev,
                        processedLinks: processed,
                        progress: currentProgress
                    }));

                    console.log(`Progress: ${currentProgress}% (${processed}/${urls.length})`);
                }

                setSuccessMsg('Bulk operation completed successfully!');
                setStatus(prev => ({ ...prev, isProcessing: false }));
            };
            reader.readAsText(file);

        } catch (error) {
            const errorMessage = error.response?.data?.errorMessage || error.message || 'An error occurred during processing.';
            setErrorMsg(errorMessage);
            console.error('Full error:', error);
            setStatus(prev => ({ ...prev, isProcessing: false }));
        }
    };
    
    const handleExport = async () => {
        try {
            const response = await exportCampaignLinks(campaignId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Campaign_Links_${campaignName}.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            setErrorMsg('Error exporting campaign links');
            console.error('Error exporting campaign links:', error);
        }
    };

    return (
        <Fragment>
        <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">
                    <div class="row align-items-center">
                        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                            
                            <h1 class="h2 mb-0 ls-tight">Bulk Operations</h1>
                        </div>
                        
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                       
                       </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
                <div class="container-fluid">

                <div class="card shadow border-0 mb-7 mt-7">
        <div className="container mt-3">
           
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                    <label htmlFor="campaignName" className="form-label">Campaign Name</label>
                    <input type="text" className="form-control" id="campaignName" value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="file" className="form-label">Upload CSV File</label>
                    <input type="file" className="form-control" id="file" onChange={handleFileUpload} accept=".csv,.txt" />
                </div>
                
                <button type="submit" className="btn btn-primary mb-3" disabled={status.isProcessing}>
                {status.isProcessing ? 'Processing...' : 'Start Bulk Operation'}
                </button>
            </form>
            {status.isProcessing && (
                                <div className="mt-3">
                                    <div className="progress mb-3" style={{ height: '25px' }}>
                                        <div 
                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" 
                                            style={{
                                                width: `${status.progress}%`,
                                                transition: 'width 0.5s ease-in-out'
                                            }} 
                                            aria-valuenow={status.progress} 
                                            aria-valuemin="0" 
                                            aria-valuemax="100"
                                        >
                                            {status.progress}%
                                        </div>
                                    </div>
                                    <div className="text-center mb-3">
                                        <p className="mb-2">
                                            Processed {status.processedLinks.toLocaleString()} out of {status.totalLinks.toLocaleString()} links
                                        </p>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="spinner-border spinner-border-sm text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            Processing...
                                        </div>
                                    </div>
                                </div>
                            )}

                            {errorMsg && showErrorMsg(errorMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                            
                            {!status.isProcessing && campaignId && (
                                <div className="mt-3 mb-3">
                                    <button className="btn btn-outline-success" onClick={handleExport}>
                                        <i className="fas fa-cloud-download-alt"></i> Export Links
                                    </button>
                                </div>
                            )}
            </div>
        </div>
        </div>
        </main>
        </Fragment>
    );
};

export default BulkOperations;